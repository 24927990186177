<template>
    <div class="report-height">
        <vue-header title="辅助决策报告" :fixed="true" rightName="意见反馈" :rightFun="()=>change({
            key:'Bool',
            value:true
        })" :returns="returnFun" />
        <div class="report-color"></div>
        <div class="round1"></div>
        <div class="round2"></div>
        <div class="round3"></div>
        <div class="report-box" v-if="user">
            <div class="report-head">
                <div class="report-port">
                    <img :src="user.avatarUrl" v-if="user.avatarUrl"/>
                    <img src="../../../images/user.png" v-else/>
                </div>
                <div class="report-name flex flex-y">
                    <div class="flex-1 report-sex"></div>
                    <div>{{user.nikeName?user.nikeName:user.name}}</div>
                    <div class="flex-1 report-sex" v-if="data.sex==1"><img src="../../../images/1.png"/>男</div>
                    <div class="flex-1 report-sex girl" v-else><img src="../../../images/2.png"/>女</div>
                </div>
                <div class="flex flex-x flex-y">
                    <div class="user-tel flex">
                        <a :href="`tel:${data.user_tel}`">{{data.user_tel}}</a>
                        <div class="user-id" v-if="data.user_idcard">{{data.user_idcard}}</div>
                    </div>
                </div>
                <div class="flex">
                    <div class="report-list flex-1 flex flex-y flex-x">
                        <div>
                            <div class="report-age">年龄</div>
                            <div class="report-num">{{GetAge(data.birthday)}}<span>岁</span></div>
                        </div>
                    </div>
                    <div class="report-list flex-1 flex flex-y flex-x">
                        <div>
                            <div class="report-age">身高</div>
                            <div class="report-num">{{data.height}}<span>cm</span></div>
                        </div>
                    </div>
                    <div class="report-list flex-1 flex flex-y flex-x">
                        <div>
                            <div class="report-age">体重</div>
                            <div class="report-num">{{data.weight}}<span>kg</span></div>
                        </div>
                    </div>
                </div>
                <div class="flex">
                    <div class="flex-1">
                        <div class="inquiry" @click="()=>change({
                            key:'show',
                            value:true
                         })">
                            查看面、舌图<van-icon name="arrow" />
                        </div>
                    </div>
                    <!-- <div class="flex-1">
                        <router-link :to="{name:'result',query:{id:this.$route.query.id}}" class="inquiry">
                            查看问诊结果<van-icon name="arrow" />
                        </router-link>
                    </div> -->
                </div>
                
            </div>
            <div class="report-index">
                <div class="report-weight">体重指数（BMI）</div>
                <div class="flex report-info">
                    <div class="info-left flex flex-y flex-x">
                        <div>
                            <div class="report-key">我的指数</div>
                            <div class="report-value">{{(data.weight / ((data.height * data.height) / 10000)).toFixed(1)}}</div>
                            <div v-html="MBItext((data.weight / ((data.height * data.height) / 10000)).toFixed(1))"></div>
                        </div>
                    </div>
                    <div class="info-right flex flex-y flex-x flex-1">
                        <div>
                            <div class="report-key">我的适合体重</div>
                            <div class="report-value">{{((22 * (data.height * data.height)) / 10000).toFixed(1)}}<span>kg</span></div>
                        </div>
                    </div>
                </div>
                <div class="flex report-fat">
                    <div class="flex-1 flex flex-x flex-y">
                        <div>
                            <div class="fat-title">偏廋</div>
                            <div class="fat-text">&lt;18.5</div>
                        </div>
                    </div>
                    <div class="flex-1 flex flex-x flex-y">
                        <div>
                            <div class="fat-title">正常</div>
                            <div class="fat-text">18.5~22.9</div>
                        </div>
                    </div>
                    <div class="flex-1 flex flex-x flex-y">
                        <div>
                            <div class="fat-title">偏胖</div>
                            <div class="fat-text">23~24.9</div>
                        </div>
                    </div>
                    <div class="flex-1 flex flex-x flex-y">
                        <div>
                            <div class="fat-title">肥胖</div>
                            <div class="fat-text">25~29.9</div>
                        </div>
                    </div>
                    <div class="flex-1 flex flex-x flex-y">
                        <div>
                            <div class="fat-title">重度肥胖</div>
                            <div class="fat-text">≥30</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="report-index">
                <div class="report-weight">问诊结果</div>
                <div class="report-info" v-if="ask">
                    <div class="report-desc desc-green">
                        <div v-for="(items,indexs) in ask" :key="indexs">
                            <div class="title-s" v-if="items['s']">{{items['s']}}</div>
                            <div class="title-s" v-else>{{items['p']}}</div>
                        </div>
                    </div>
                </div>
                <div class="report-info" v-if="data.description">
                    <div class="report-desc desc-green">
                        {{data.description}}
                    </div>
                </div>
            </div>
            <div class="report-alert">患者的症状过多，建议优先考虑当前最想解决的症状，选择相应的方剂。</div>
            <div class="report-prescript" v-if="content.length>0">
                <div class="report-weight">当前证候</div>
                <div class="report-auto">
                    <div class="flex">
                        <div class="symptom" v-for="(items,indexs) in content" 
                                             v-bind:key="indexs"
                                             v-bind:class="{active:indexs==contentIndex}"
                                             @click="()=>change({
                                                 key:'contentIndex',
                                                 value:indexs
                                             })"
                        >{{items.key}}</div>
                    </div>
                </div>
                <div class="report-drug">
                    <div class="drug-title">
                        当前的病因
                    </div>
                    <ul class="drug-ul">
                        <li v-for="(item,index) in content[contentIndex].value.cause_disease.list" v-bind:key="index" >{{item.causeDisease}}</li>
                    </ul>
                </div>
                <div class="report-drug" v-if="data.chinese_patent_type==1||data.chinese_patent_type==3">
                    <div class="drug-title">
                        推荐中成药
                    </div>
                    <div class="drug-text" v-for="(items,indexs) in content[contentIndex].value.chinese_patent.list" v-bind:key="indexs">
                        <div class="drug-pool">
                            <div class="pool-text">{{items.name}}</div>
                        </div>
                    </div>
                </div>
                <div class="report-drug" v-if="data.chinese_patent_type==2||data.chinese_patent_type==3">
                    <div class="drug-title">
                        推荐药方
                    </div>
                    <div class="drug-text" v-for="(items,indexs) in content[contentIndex].value.composition.list" v-bind:key="indexs">
                        <div class="drug-name">{{items.name}}</div>
                        <div class="drug-pool">
                            <div class="pool-box" >
                                <div class="pool-title">
                                    药方配料：
                                </div>
                                <div class="pool-text">
                                    {{items.composition}}
                                </div>
                            </div>
                            <div class="pool-box">
                                <div class="pool-title">
                                    适用病证：
                                </div>
                                <div class="pool-text">
                                    {{items.suitableSick}}
                                </div>
                            </div>
                            <div class="pool-box" v-if="items.tips">
                                <div class="pool-title">
                                    注意事项：
                                </div>
                                <div class="pool-text">
                                    {{items.tips}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else-if="doctor" class="customize">
                <div class="customize-box" v-for="(item,index) in customize" :key="index">
                    <div class="flex customize-li flex-y" >
                        <div class="syndrome"><span>*</span>当前证候</div>
                        <div class="flex-1">
                            <input type="text" placeholder="请输入证候" v-model="item.sick_text" />
                        </div>
                    </div>
                    <div class="flex customize-li">
                        <div class="syndrome"><span>*</span>当前病因</div>
                        <div class="flex-1">
                            <textarea placeholder="请输入当前病因" v-model="item.causeDisease" ></textarea>
                        </div>
                    </div>
                    <div class="flex customize-li flex-y">
                        <div class="syndrome"><span>*</span>推荐药方</div>
                        <div class="flex-1">
                            <input type="text" placeholder="请输入推荐药方" v-model="item.drug"/>
                        </div>
                    </div>
                    <div class="flex customize-li">
                        <div class="syndrome"><span>*</span>药方配料</div>
                        <div class="flex-1">
                            <textarea placeholder="请输入药方配料" v-model="item.composition"></textarea>
                        </div>
                    </div>
                    <div class="flex customize-li">
                        <div class="syndrome"><span>*</span>适用症状</div>
                        <div class="flex-1">
                            <textarea placeholder="请输入药方配料" v-model="item.suitableSick"></textarea>
                        </div>
                    </div>
                    <div class="flex customize-li">
                        <div class="syndrome"><span> </span>注意事项</div>
                        <div class="flex-1">
                            <textarea placeholder="请输入药方配料" v-model="item.tips"></textarea>
                        </div>
                    </div>
                    <div class="customize-delete flex flex-x">
                        <div @click="()=>customizeDelete(index)">
                            <van-icon name="delete-o" />
                        </div>
                    </div>
                </div>
                <div class="customize-footer">
                    <div class="footer-ize flex flex-x flex-y">
                        <div class="flex">
                            <div class="footer-add" @click="customizeFun">添加证候</div>
                            <div class="footer-submit" v-if="customize.length>0" @click="customizeSubmit">保存</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="Bool" class="alert">
            <div class="alert-box">
                <div class="alert-title">反馈</div>
                <div class="alert-body">
                    <textarea placeholder="请填写您宝贵的建议及意见" v-model="feedback"></textarea>
                </div>
                <div class="alert-footer flex">
                    <div class="cancel flex-1" @click="feedbackClone"
                    >取消</div>
                    <div class="flex-1" @click="feedbackFun">确定</div>
                </div>
            </div>
        </div>
        <van-image-preview v-model="show" :images="images" @change="(val)=>change({
            key:'imagesIndex',
            value:val
        })" v-if="images.length>0">
            <template v-slot:imagesIndex>{{ imagesIndex==0?'面诊图':'舌诊图' }}</template>
        </van-image-preview>
    </div>
</template>

<script>
import { mapActions , mapState , mapMutations } from 'vuex';
import vueHeader from '@/components/header';
export default {
  name: 'report',
  data(){
      return {
         customize:[],
      }
  },
  components:{
    vueHeader
  },
  created:function(){
      if(this.$route.params.id){
          this.axiosData({token:this.$route.params.id});
          this.askData({token:this.$route.params.id});
          this.accountData();
          scrollTo(0,0);
      }
  },
  computed:{
        show:{
            get() { return this.$store.state.doctorReport.show },
            set(val){this.change({
                key:'show',
                value:val
            });}
        },
        feedback:{
            get() { return this.$store.state.doctorReport.feedback },
            set(val){this.change({
                key:'feedback',
                value:val
            });}
        },
        ...mapState('doctorReport',['data','content','contentIndex','Bool' , 'images' ,'imagesIndex','ask','user']),
        ...mapState('prescribeQuestion',['doctor']),
  },
  methods:{
      onChange(index) {
        this.index = index;
      },
      customizeFun(){
          this.customize.push({
             sick_text:'',
             causeDisease:'',
             drug:'',
             composition:'',
             suitableSick:'',
             tips:''
         });
      },
      //返回
      returnFun(){
        if(this.$route.query.return==2){
            this.$router.back('-1');
        }else{
            this.$router.push({path: `/user`});
        }
      },
      customizeDelete(index){
          this.customize.splice(index,1);
      },
      customizeSubmit(){
          this.customizeData({
              id:this.$route.params.id,
              data:this.customize
          });
      },
      back(){
          window.history.back(-1);
      },
      feedbackClone(){
        this.change({key:'Bool',value:false});
        this.change({key:'feedback',value:''});
      },
      feedbackFun(){
          this.feedbackData({id:this.$route.params.id});
      },
      MBItext(MBI) {
            if (MBI <= 18.5) {
                return '<div class="report-state orange">偏瘦</div>';
            } else if (MBI >= 18.5 && MBI <= 23.9) {
                return '<div class="report-state">正常</div>';
            } else if (MBI >= 24 && MBI <= 26.9) {
                return '<div class="report-state orange">偏胖</div>';
            } else if (MBI >= 27 && MBI <= 29.9) {
                return '<div class="report-state red">肥胖</div>';
            } else if (MBI >= 30) {
                return '<div class="report-state red">重度肥胖</div>';
            }
      },
      GetAge(strBirthday){
        let returnAge,
            strBirthdayArr=strBirthday.split("-"),
            birthYear = strBirthdayArr[0],
            birthMonth = strBirthdayArr[1],
            birthDay = strBirthdayArr[2],  
            d = new Date(),
            nowYear = d.getFullYear(),
            nowMonth = d.getMonth() + 1,
            nowDay = d.getDate();   
        if(nowYear == birthYear){
            returnAge = 0;//同年 则为0周岁
        }
        else{
            let ageDiff = nowYear - birthYear ; //年之差
            if(ageDiff > 0){
                if(nowMonth == birthMonth) {
                    let dayDiff = nowDay - birthDay;//日之差
                    if(dayDiff < 0) {
                        returnAge = ageDiff - 1;
                    }else {
                        returnAge = ageDiff;
                    }
                }else {
                    let monthDiff = nowMonth - birthMonth;//月之差
                    if(monthDiff < 0) {
                        returnAge = ageDiff - 1;
                    }
                    else {
                        returnAge = ageDiff ;
                    }
                }
            }else {
                returnAge = -1;//返回-1 表示出生日期输入错误 晚于今天
            }
        } 
        return returnAge;//返回周岁年龄
      },   
      ...mapMutations('doctorReport',['change','clearFun']),
      ...mapActions('doctorReport',['axiosData','customizeData','askData','feedbackData']),
      ...mapActions('prescribeQuestion',['accountData'])
  },
  destroyed(){
      this.clearFun();
  }
}
</script>

<style lang="scss" scoped> 
    @import './doctorReport'; 
</style>